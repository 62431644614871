import React, { createRef, useEffect, useState } from "react";
import CONSTANTS from "common/constants";
import { addUser, deleteUser } from "api/userManagementAPI";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { BreadCrumb } from "components/BreadCrumb";
import TableRowCount from "components/TableRowCount";
import FocusTrap from "focus-trap-react";
import { useLocation } from "react-router-dom";
import { UserManagementWrapper } from "./styled";
import {
    localDateToYYYYMMDDFormatString,
    parseJwt,
    showModal,
} from "../../common/utils";
import { getHospitalById } from "../../api/hospitalApi";
import SmartSearch from "../SmartSearch";

interface users {
    userFirstName: string;
    userLastName: string;
    userContactEmail: string;
}
interface Props {
    users: Array<users>;
    isTableLoading: boolean;
    searchUser: any;
    inputUser: any;
    setInputUser: any;
    searchFilter: boolean;
    searchkeywordLength: number;
    isUsersResponseAvailable: boolean;
    roleValue: string;
    pagination: any;
}

export default function UserManagement({
    users,
    isTableLoading,
    searchUser,
    inputUser,
    setInputUser,
    searchFilter,
    searchkeywordLength,
    isUsersResponseAvailable,
    roleValue,
    pagination,
}: Props) {
    const location = useLocation();
    const [validateDisabled, setValidateDisabled] = useState<boolean>(false);
    const addUserCancelButton = createRef<HTMLButtonElement>();
    const deleteUserCancelButton = createRef<HTMLButtonElement>();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const programId: any = localStorage.getItem("selectedProgramId");
    const categoryId: any = localStorage.getItem("selectedCategoryId");
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ==
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
    const [addUserActivateInd, setAddUserActivateInd] =
        useState<boolean>(false);
    const [deleteUserActivateInd, setDeleteUserActivateInd] =
        useState<boolean>(false);
    const [disabledInd, setDisabledInd] = useState<boolean>(false);

    useEffect(() => {
        const elementsArray: any = [];
        localStorage.setItem("clearFilter", "true");
        if (isEUP) {
            getHospitalById(hospitalId).then((response: any) => {
                if (response.success) {
                    if (programId !== CONSTANTS.EMS_PROGRAM_ID) {
                        elementsArray.push(
                            {
                                label: "Home",
                                returnPath: {
                                    pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: response.data?.hospital?.facilityName,
                                returnPath: {
                                    pathname: "/dashboard",
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: "User Management",
                                returnPath: "",
                            }
                        );
                    } else {
                        elementsArray.push(
                            {
                                label: "Home",
                                returnPath: {
                                    pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: response.data?.hospital?.facilityName,
                                returnPath: {
                                    pathname: `/organizationDetails/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                                    state: {
                                        activateMeasuresTab: true,
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: "User Management",
                                returnPath: "",
                            }
                        );
                    }
                    setBreadcrumbItems(elementsArray);
                }
            });
        } else if (isCorporation) {
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: "/corporation/dashboard",
                },
                {
                    label: "User Management",
                    returnPath: "",
                }
            );
            setBreadcrumbItems(elementsArray);
        }
    }, []);

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });

    const dispatch = useDispatch();
    const [userEmail, setUserEmail] = useState<any>("");
    const [deleteUserId, setDeleteUserId] = useState<any>("");

    const addUserDetails = async (event: any) => {
        event.preventDefault();
        const cancelButtonRef = addUserCancelButton.current;
        setValidateDisabled(true);
        const userResult = await addUser(
            hospitalId,
            userEmail,
            setValidateDisabled,
            isEUP
        );

        if (userResult.success) {
            const toast = {
                message: `User ${userEmail} successfully added.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            cancelButtonRef && cancelButtonRef.click();
            searchUser();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
        setValidateDisabled(false);
    };

    const deleteUserData = async () => {
        setDisabledInd(true);
        const userResult = await deleteUser(deleteUserId, hospitalId, isEUP);
        setDisabledInd(false);
        if (userResult.success) {
            const toast = {
                message: `User ${userEmail} has been removed.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            deleteUserDismissModal();
            searchUser();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };

    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const addUserDismissModal = () => {
        setAddUserActivateInd(false);
        showModal();
    };

    const deleteUserDismissModal = () => {
        setDeleteUserActivateInd(false);
        showModal();
    };

    return (
        <UserManagementWrapper>
            <div className="container">
                {(isEUP || isCorporation) && (
                    <div className="mb-n4">
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                    </div>
                )}
                <h1 className="h3 mb-4 mt-5">User Management</h1>
                <div className="usr-add-search d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-5">
                    <div className="usr-search mb-4 mb-sm-0">
                        <SmartSearch
                            searchKey={inputUser}
                            setSearchKey={setInputUser}
                            getSearchedItems={searchUser}
                            placeholder="Search By Name or Email ID"
                            arialabel=""
                            id="user-mgnt-search"
                        />
                    </div>
                    {isUsersResponseAvailable && users.length < 4 && (
                        <button
                            id="adduserbutton"
                            onClick={() => {
                                setUserEmail("");
                                setAddUserActivateInd(true);
                                showModal();
                            }}
                            className="btn btn-primary btn-round"
                        >
                            Add User
                        </button>
                    )}
                    {isUsersResponseAvailable && users.length >= 4 && (
                        <div
                            id="add-user-btn"
                            className="d-inline-block add-usermgnt-btn"
                        >
                            <button
                                id="disabledadduserbutton"
                                className="btn btn-primary btn-round"
                                data-toggle="popover"
                                {...(!isSafariBrowser()
                                    ? { "data-trigger": "focus" }
                                    : {})}
                                data-content="Sorry, you have reached your maximum limit of 5 users"
                                data-placement="bottom"
                                data-container="#add-user-btn"
                            >
                                Add User
                            </button>
                        </div>
                    )}
                    {/* Add User Modal */}
                    {addUserActivateInd ? (
                        <FocusTrap
                            focusTrapOptions={{
                                escapeDeactivates: false,
                                clickOutsideDeactivates: false,
                            }}
                        >
                            <div
                                className="modal fade show aui-modal adduser-modal"
                                id="addusermodal"
                                tabIndex={-1}
                                aria-labelledby="adduserLabel"
                                aria-modal="true"
                                role="dialog"
                                style={{
                                    display: addUserActivateInd
                                        ? "block"
                                        : "none",
                                }}
                            >
                                <div className="modal-dialog modal-dialog-centered modal-md">
                                    <div className="modal-content">
                                        <div className="modal-header mb-4">
                                            <h2
                                                className="h4"
                                                id="adduserLabel"
                                            >
                                                Validate Email Address
                                            </h2>
                                            <button
                                                type="button"
                                                className="close"
                                                aria-label="Close Validate email address modal"
                                                onClick={addUserDismissModal}
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    className="aha-icon-cross"
                                                />
                                            </button>
                                        </div>
                                        <form
                                            className="reg-form"
                                            onSubmit={addUserDetails}
                                        >
                                            <div className="modal-body mb-0">
                                                <div className="form-group row required mb-4">
                                                    <div className="col-12">
                                                        <input
                                                            type="email"
                                                            value={userEmail}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setUserEmail(
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                            className="form-control"
                                                            id="registeredEmailId"
                                                            disabled={
                                                                validateDisabled
                                                            }
                                                            required
                                                            placeholder="Enter SSO Registered Email Address"
                                                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-md-flex flex-row-reverse align-items-center confirmEmail-btns">
                                                    <div className="d-flex flex-sm-row flex-column">
                                                        <button
                                                            ref={
                                                                addUserCancelButton
                                                            }
                                                            type="button"
                                                            className="btn btn-secondary btn-round"
                                                            onClick={
                                                                addUserDismissModal
                                                            }
                                                            aria-label="Cancel Validating Email address"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            disabled={
                                                                validateDisabled
                                                            }
                                                            className={`btn btn-primary btn-round mt-4 mt-sm-0 ml-sm-4 ${
                                                                validateDisabled
                                                                    ? CONSTANTS.BUTTON_SPINNER
                                                                    : ""
                                                            }`}
                                                            aria-label="Validate Email address"
                                                        >
                                                            Validate
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </FocusTrap>
                    ) : null}
                </div>
                {isTableLoading ? (
                    <div
                        role="alert"
                        aria-live="assertive"
                        aria-label="User management table is loading"
                        className="sr-only"
                    />
                ) : (
                    ""
                )}
                {pagination && (
                    <TableRowCount
                        isFirst={pagination.isFirst}
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        isLast={pagination.isLast}
                        totalCount={pagination.totalCount}
                        roleValue={roleValue}
                        userManagementInd
                    />
                )}
                <div className="usr-table mb-5 mt-3">
                    <table
                        className={`aui-responsive-status-table ${
                            isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                        }`}
                    >
                        <thead>
                            <tr>
                                <th scope="col">
                                    <div className="aui-th">First Name</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Last Name</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Email</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Created Date</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th justify-content-center">
                                        Action
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 &&
                                users.map((user: any, index: number) => {
                                    return (
                                        <tr
                                            key={`user-${user.user.userId}`}
                                            className="aui-table-status-normal"
                                        >
                                            <td data-title="First Name">
                                                <div
                                                    className="aui-td"
                                                    id={`fname-${user.user.userId}`}
                                                >
                                                    {user.user.userFirstName}
                                                </div>
                                            </td>
                                            <td data-title="Last Name">
                                                <div
                                                    className="aui-td"
                                                    id={`lname-${user.user.userId}`}
                                                >
                                                    {user.user.userLastName}
                                                </div>
                                            </td>
                                            <td data-title="Email">
                                                <div className="aui-td">
                                                    {user.user.userContactEmail}
                                                </div>
                                            </td>
                                            <td data-title="Created Date">
                                                <div className="aui-td">
                                                    {localDateToYYYYMMDDFormatString(
                                                        new Date(user.createdAt)
                                                    )}
                                                </div>
                                            </td>
                                            <td data-title="Action">
                                                <div className="aui-td justify-content-center">
                                                    <div
                                                        data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Delete"
                                                        className="d-inline-block"
                                                    >
                                                        <button
                                                            className="btn btn-text usr-action-icon"
                                                            onClick={() => {
                                                                setDeleteUserId(
                                                                    user.user
                                                                        .userId
                                                                );
                                                                setUserEmail(
                                                                    user.user
                                                                        .userContactEmail
                                                                );
                                                                setDeleteUserActivateInd(
                                                                    true
                                                                );
                                                                showModal();
                                                            }}
                                                        >
                                                            <i
                                                                className="aha-icon-trash"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="sr-only">
                                                                {
                                                                    user.user
                                                                        .userFirstName
                                                                }{" "}
                                                                {
                                                                    user.user
                                                                        .userLastName
                                                                }
                                                                delete
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                    <div
                        role="alert"
                        aria-atomic="true"
                        className="table-emptystate-outer"
                    >
                        {users.length < 1 && <div>No data to display</div>}
                    </div>
                    {/* Conformation modal */}
                    {deleteUserActivateInd ? (
                        <FocusTrap
                            focusTrapOptions={{
                                escapeDeactivates: false,
                                clickOutsideDeactivates: false,
                            }}
                        >
                            <div
                                className="modal fade show aui-modal"
                                id="deleteusermodal"
                                tabIndex={-1}
                                aria-labelledby="modalDeleteUserLabel"
                                aria-modal="true"
                                role="dialog"
                                style={{
                                    display: deleteUserActivateInd
                                        ? "block"
                                        : "none",
                                }}
                            >
                                <div className="modal-dialog modal-dialog-centered modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-body p-0">
                                            <div className="text-center">
                                                <p id="modalDeleteUserLabel">
                                                    Are you sure you want to
                                                    delete this user?
                                                </p>
                                                <div className="mt-4 d-flex justify-content-center">
                                                    <button
                                                        disabled={disabledInd}
                                                        className="btn btn-secondary btn-round mx-2 px-5"
                                                        ref={
                                                            deleteUserCancelButton
                                                        }
                                                        onClick={
                                                            deleteUserDismissModal
                                                        }
                                                    >
                                                        No
                                                    </button>
                                                    <button
                                                        disabled={disabledInd}
                                                        className={`btn btn-primary btn-round mx-2 px-5 ${
                                                            disabledInd
                                                                ? CONSTANTS.BUTTON_SPINNER
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            deleteUserData();
                                                        }}
                                                    >
                                                        Yes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FocusTrap>
                    ) : null}
                </div>
            </div>
        </UserManagementWrapper>
    );
}
