import React, { useEffect, useRef, useState } from "react";
import {
    dateToYYYYMMDDFormatString,
    cstDateFormat,
    dateToYYYYMMDDPlaceholderFormatStringISO,
    wordSplit,
} from "common/utils";
import { DatepickerWrapper } from "./styled";

export const daysBetweenTwoDates = (currentDate1: any, expireDate: any) => {
    const year: any = currentDate1.getFullYear();
    const month: any = `0${currentDate1.getMonth() + 1}`.slice(-2);
    const day: any = currentDate1.getDate();
    const currentDate = `${year}-${month}-${day}`;
    const timeDifference =
        new Date(
            dateToYYYYMMDDPlaceholderFormatStringISO(currentDate)
        ).getTime() -
        new Date(dateToYYYYMMDDFormatString(expireDate)).getTime();
    const daysDiffererence = Math.round(timeDifference / (1000 * 3600 * 24));
    return daysDiffererence;
};

export const getSysTimeZoneCurrentDate = () => {
    const cstOptions: any = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    };
    const cstFormatter = new Intl.DateTimeFormat("en-US", cstOptions);
    const fullDate = cstFormatter.format(new Date()).split(",");
    const [m, d, y] = fullDate[0].split("/");
    return `${y}-${m}-${d}`;
};
const Datepicker = (props: any) => {
    const months: any = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
    };
    const monthArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const [dateLabel, setDateLabel] = useState<string>("Open datepicker");
    const [propsYear, propsMonth, propsDay] = props.defaultValue.split("-");
    const [actualDate, setactualDate] = useState<any>(null);
    const [dummyDate, setDummyDate] = useState<any>(null);
    const key = Object.keys(months).filter((key) => {
        return propsMonth === months[key];
    })[0];
    const previousServiceYearRef = useRef(props.activeServiceYear);
    const [textYear, setTextYear] = useState<any>(propsYear);
    const [textMonth, setTextMonth] = useState<any>(key);
    const [textDay, setTextDay] = useState<any>(propsDay);
    const yearValue = useRef<HTMLInputElement>(null);
    const monthValue = useRef<HTMLInputElement>(null);
    const dayValue = useRef<HTMLInputElement>(null);
    const actualDateRef = useRef<HTMLInputElement>(null);
    const datePickerBtnRef = useRef<HTMLButtonElement>(null);
    const [dateError, setDateError] = useState<any>("");
    const [presentYear, setPresentYear] = useState<any>("");
    const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
    const [minDateForLock, setMinDateForLock] = useState<any>("");
    const [isCstDate, setIsCstDate] = useState<boolean>(false);
    const [isDateDiff, setIsDateDiff] = useState<any>();
    const [isNextYear, setIsNextYear] = useState<boolean>(false);
    const idLabels: any = `${props.ariaLabel} date-error`;
    const cstOptions: any = {
        timeZone: "America/Chicago",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
    };
    const cstFormatter = new Intl.DateTimeFormat("en-US", cstOptions);
    const getCSTDateFormat = (date: any) => {
        const diff = daysBetweenTwoDates(new Date(), date);
        if (diff === 2 && props?.isCstCurrentDate) {
            setIsCstDate(true);
            const fullDate = cstFormatter.format(new Date()).split(",");
            const [m, d, y] = fullDate[0].split("/");
            return `${y}-${m}-${d}`;
        }
        return date;
    };
    const validateYears = (year: any) => {
        if (props?.minMaxDate && isNextYear) {
            const nextYear =
                props.activeServiceYear + 1 < new Date().getFullYear()
                    ? new Date().getFullYear()
                    : props.activeServiceYear + 1;
            if (+year <= +props.activeServiceYear || +year > nextYear) {
                return true;
            }
            return false;
        }
        return false;
    };
    const validateDays = (days: any) => {
        const month = months[`${monthValue.current?.value}`];
        const numberOfDays = getDaysInMonth(yearValue.current?.value, month);
        if (days > numberOfDays || days === "00" || days === "0") {
            return true;
        }
        return false;
    };
    const validateMonth = () => {
        if (props?.minMaxDate) {
            const month = months[`${monthValue.current?.value}`]; // Assuming 'months' is a mapping object for month names
            const { currentCstDate } = cstYear();
            const formattedCstDate = new Date(currentCstDate); // Full CST Date object
            const calendarYear =
                props.activeServiceYear + 1 < new Date().getFullYear()
                    ? new Date().getFullYear()
                    : props.activeServiceYear + 1;
            const nextYearJanFirst = new Date(`${calendarYear}-01-01`); // Full Date object for Jan 1st of the next year

            if (nextYearJanFirst > formattedCstDate) {
                // Compare the month of the input with the current CST month
                if (
                    Number(month) <
                    new Date(`${calendarYear}-01-01`).getMonth() + 1
                ) {
                    return true;
                }
            } else if (Number(month) < new Date().getMonth() + 1) {
                return true;
            }
        }
        return false;
    };
    const validateMaxDays = (days: any) => {
        const { currentCstDate } = cstYear();
        const formattedCstDate = new Date(currentCstDate); // Full CST Date object
        const calendarYear =
            props.activeServiceYear + 1 < new Date().getFullYear()
                ? new Date().getFullYear()
                : props.activeServiceYear + 1;
        const nextYearJanFirst = new Date(`${calendarYear}-01-01`); // Full Date object for Jan 1st of the next year

        const month = months[`${monthValue.current?.value}`];
        let today;
        const numberOfDays = getDaysInMonth(yearValue.current?.value, month);
        if (nextYearJanFirst > formattedCstDate) {
            today = nextYearJanFirst.getDate();
            if (days > numberOfDays || days === "00" || days === "0") {
                return true;
            }
            if (
                days < numberOfDays &&
                days < today &&
                props?.minMaxDate &&
                Number(month) === nextYearJanFirst.getMonth() + 1
            ) {
                return true;
            }
            return false;
        }

        today = new Date().getDate();
        if (days > numberOfDays || days === "00" || days === "0") {
            return true;
        }
        if (
            days < numberOfDays &&
            days < today &&
            props?.minMaxDate &&
            Number(month) === new Date().getMonth() + 1
        ) {
            return true;
        }
        return false;
    };
    const handleDateChange = (event: any) => {
        if (event.target.value) {
            const cstDate: any = event.target.value;
            props.onChangeDate(cstDate);
            setactualDate(cstDate);
            const [year, month, day] = cstDate.split("-");
            const key = Object.keys(months).filter((key) => {
                return month === months[key];
            })[0];
            setDummyDate(`${year}-${key}-${day}`);
            setTextYear(year);
            setTextMonth(key);
            setTextDay(day);
            actualDateRef.current?.focus();
            dayValue?.current?.focus();
        } else {
            setTextYear("");
            setTextMonth("");
            setTextDay("");
        }
    };
    const updateTextDate = (y: any, m: any, d: any) => {
        const mnth = monthArray.indexOf(`${m}`);
        const mnt = (mnth + 1).toString().padStart(2, "0");
        const day = Number(d);
        props.onChangeDate(`${y}-${mnt}-${`0${d}`.slice(-2)}`);
    };

    const triggerCallendar = (event: any) => {
        if (event.which === 9) {
            document.getElementById("lockCancel")?.focus();
        }
    };
    const handleTextDateChange = (event: any) => {
        event.target.parentNode.title = "";
        event.target.setCustomValidity("");
        if (!yearValue.current?.value) {
            setTextYear("");
        }
        if (!monthValue.current?.value) {
            setTextMonth("");
        }
        let val: any = "";
        if (monthValue.current?.id === "monthValue") {
            val = event.target.value.replace("[A-Za-z]+", "");
        }
        if (dayValue.current) {
            const dayVal = dayValue.current?.value;
            // If day is empty or an invalid value, reset it to "DD"
            if (!dayVal) {
                setTextDay("");
            } else if (dayVal === "00" || dayVal === "0" || dayVal === "") {
                dayValue.current.value = ""; // Clear the input value
                const dayElement = document.getElementById(
                    "dayValue"
                ) as HTMLInputElement;
                if (dayElement) {
                    dayElement.placeholder = "D D"; // Or dayElement.placeholder = "D D";
                    setTextDay("");
                }
            } else {
                setTextDay(`${`0${dayValue.current?.value}`.slice(-2)}`);
            }
        }
        props.setBtnInd(false);
        setTextYear(yearValue.current?.value);
        setTextMonth(monthValue.current?.value);
        updateTextDate(
            yearValue.current?.value,
            monthValue.current?.value,
            dayValue.current?.value
        );
    };
    const cstYear = () => {
        const currentDate = new Date();
        const cstTimeZone = "America/Chicago";
        const currentCstDate = currentDate.toLocaleDateString("en-US", {
            timeZone: cstTimeZone,
        });
        const Year = new Date(currentCstDate).getFullYear();
        return { Year, currentCstDate };
    };
    const onCstDateDiff = () => {
        const fullDate = cstFormatter.format(new Date()).split(",");
        const [m, d, y] = fullDate[0].split("/");
        const cstDate: any = `${y}-${m}-${d}`;
        const { Year, currentCstDate } = cstYear();
        const diff = daysBetweenTwoDates(new Date(), currentCstDate);
        if (diff === 2) {
            setIsCstDate(true);
            setIsDateDiff(diff);
        }
        const formattedCstDate = new Date(currentCstDate);
        const month = (formattedCstDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
        const day = formattedCstDate.getDate().toString().padStart(2, "0");
        const minDate = `${formattedCstDate.getFullYear()}-${month}-${day}`;
        setPresentYear(Year);
        setMinDateForLock(minDate);
    };
    useEffect(() => {
        onCstDateDiff();
    }, []);

    useEffect(() => {
        if (textYear || textMonth || textDay) {
            const datePattern =
                /^\d{4}-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{2}$/;
            const dateString = `${textYear}-${textMonth}-${`0${textDay}`.slice(
                -2
            )}`;
            if (
                datePattern.test(dateString) &&
                !validateDays(textDay) &&
                !validateYears(textYear) &&
                !validateMonth() &&
                !validateMaxDays(textDay)
            ) {
                setactualDate(
                    `${textYear}-${months[textMonth]}-${`0${textDay}`.slice(
                        -2
                    )}`
                );
                setDummyDate(
                    `${textYear}-${textMonth}-${`0${textDay}`.slice(-2)}`
                );
                setDateError("");
            } else {
                props?.setBtnInd(true);
                const today = new Date();
                const lessDays: any = isCstDate ? 2 : 1;
                let yesterday: any;

                if (
                    previousServiceYearRef.current !== props.activeServiceYear
                ) {
                    const calendarYear =
                        props.activeServiceYear + 1 < new Date().getFullYear()
                            ? new Date().getFullYear()
                            : props.activeServiceYear + 1;
                    // Create a new date based on January 1st of the next year
                    const adjustedToday = new Date(`${calendarYear}-01-01`);
                    // Adjust the date to get yesterday's date in the next year
                    yesterday = dateToYYYYMMDDFormatString(
                        adjustedToday.setDate(
                            adjustedToday.getDate() - lessDays
                        )
                    );
                    previousServiceYearRef.current = props.activeServiceYear;
                } else {
                    // If activeServiceYear hasn't changed, use the actual current date
                    yesterday = dateToYYYYMMDDFormatString(
                        today.setDate(today.getDate() - 1)
                    );
                }

                yesterday = cstDateFormat(yesterday);
                const inYear =
                    props.activeServiceYear + 1 < new Date().getFullYear()
                        ? new Date().getFullYear()
                        : props.activeServiceYear + 1;

                if (props?.errorMessage) {
                    setDateError(props?.errorMessage);
                } else if (isNextYear) {
                    yesterday = cstDateFormat(minDateForLock);
                    setDateError(
                        `Enter a valid date after ${yesterday} in the year ${inYear}.`
                    );
                } else {
                    setDateError(
                        `Enter a valid date after ${yesterday} in the year ${inYear}.`
                    );
                }
            }
            if (
                dayValue.current?.value === "aN" ||
                monthValue.current?.value === "" ||
                yearValue.current?.value === ""
            ) {
                setTextDay((preDay: any) => preDay);
            }
        }
    }, [textYear, textMonth, textDay, isNextYear]);
    const getDaysInMonth = (year: any, month: any) =>
        new Date(year, month, 0).getDate();
    const handleYearKeyEvent = (event: any) => {
        const selectedYear = yearValue.current?.value;
        if (event.which === 38) {
            setTextYear((preVal: any) =>
                preVal === 2050 ? preVal : Number(preVal) + 1
            );
            updateTextDate(textYear, textMonth, textDay);
        }
        if (event.which === 40) {
            setTextYear((preVal: any) =>
                preVal === 1920 ? preVal : Number(preVal) - 1
            );
            updateTextDate(textYear, textMonth, textDay);
        }
    };
    const handleMonthKeyEvent = (event: any) => {
        const selected = monthArray.indexOf(`${monthValue.current?.value}`);
        if (event.which === 38) {
            setTextMonth(() =>
                selected === 11
                    ? monthArray[selected]
                    : monthArray[selected + 1]
            );
            updateTextDate(
                textYear,
                monthArray[selected === 11 ? selected : selected + 1],
                textDay
            );
        }
        if (event.which === 40) {
            setTextMonth(() =>
                selected === 0 ? monthArray[selected] : monthArray[selected - 1]
            );
            updateTextDate(
                textYear,
                monthArray[selected === 0 ? selected : selected - 1],
                textDay
            );
        }
    };
    const handleDayKeyEvent = (event: any) => {
        const month = months[`${monthValue.current?.value}`];
        const numberOfDays = getDaysInMonth(yearValue.current?.value, month);
        if (textDay > numberOfDays) {
            setTextDay(numberOfDays);
        }
        if (event.which === 38) {
            setTextDay((preVal: any) =>
                preVal >= numberOfDays ? numberOfDays : Number(preVal) + 1
            );
            updateTextDate(
                textYear,
                textMonth,
                textDay >= numberOfDays ? numberOfDays : Number(textDay) + 1
            );
        }
        if (event.which === 40) {
            setTextDay((preVal: any) =>
                preVal <= 1 ? preVal : Number(preVal) - 1
            );
            updateTextDate(
                textYear,
                textMonth,
                textDay <= 1 ? textDay : Number(textDay) - 1
            );
        }
    };
    // this is for mac Safari Browser
    const safariClass = document.querySelector("body");
    let isSafari: boolean = false;
    const handleEscKey = (event: KeyboardEvent) => {
        if (safariClass) {
            isSafari = safariClass.className.indexOf("Safari") !== -1;
        }
        if ((event.key === "Escape" || event.key === "Enter") && isSafari) {
            window.scrollTo({
                top: 5,
            });
            setIsPickerOpen(false);
        }
        window.scrollTo({
            top: 0,
        });
    };
    useEffect(() => {
        document.addEventListener("keydown", handleEscKey);
        return () => {
            document.removeEventListener("keydown", handleEscKey);
        };
    }, []);
    const showCallendar = (event: any) => {
        event.preventDefault();
        const dt: any = document.querySelector(
            "#actualDate"
        ) as HTMLAnchorElement;

        if (safariClass) {
            isSafari = safariClass.className.indexOf("Safari") !== -1;
        }
        if (isSafari) {
            const dtBtn: any = document.querySelector(
                ".date-btn"
            ) as HTMLAnchorElement;
            dtBtn.addEventListener("click", () => {
                dt.click();
            });
            setIsPickerOpen(true);
        } else {
            dt.showPicker();
            setIsPickerOpen(true);
        }
    };

    useEffect(() => {
        let propsDate;
        if (props.defaultValue === "") {
            // Default to current date
            propsDate = dateToYYYYMMDDFormatString(new Date());
        } else {
            propsDate = props.defaultValue;
        }
        const [propsYear, propsMonth, propsDay] = propsDate.split("-");
        const key = Object.keys(months).filter((key) => {
            return propsMonth === months[key];
        })[0];
        setTextYear(propsYear);
        setTextMonth(key);
        setTextDay(propsDay);
    }, [props.defaultValue]);
    useEffect(() => {
        const { currentCstDate } = cstYear();
        const formattedCstDate = new Date(currentCstDate);
        const calendarYear =
            props.activeServiceYear + 1 < new Date().getFullYear()
                ? new Date().getFullYear()
                : props.activeServiceYear + 1;
        const nextYearJanFirst = new Date(`${calendarYear}-01-01`);
        const month = (formattedCstDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
        const day = formattedCstDate.getDate().toString().padStart(2, "0");
        let minDate;
        if (nextYearJanFirst > formattedCstDate) {
            minDate = `${calendarYear}-01-01`;
            previousServiceYearRef.current = props.activeServiceYear;
            setIsNextYear(true);
        } else {
            minDate = `${formattedCstDate.getFullYear()}-${month}-${day}`;
            setIsNextYear(true);
        }

        setMinDateForLock(minDate);
        setPresentYear(calendarYear);
    }, [props.activeServiceYear]);
    return (
        <DatepickerWrapper>
            <div
                className={`input-group mb-3 border border-default date-wrapper d-flex ${
                    props.isReadOnly && "bg-light"
                }`}
                role="group"
                aria-labelledby={idLabels}
            >
                <div style={{ paddingTop: "3px", paddingLeft: "5px" }}>
                    <input
                        type="text"
                        className="border-0 date-year custom-error-tooltip select-text"
                        id="yearValue"
                        ref={yearValue}
                        placeholder={wordSplit("YYYY")}
                        value={textYear}
                        min={2000}
                        max={2090}
                        maxLength={4}
                        pattern="[0-9]+"
                        onChange={handleTextDateChange}
                        onKeyDown={handleYearKeyEvent}
                        title=""
                        required
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                        disabled={props?.isReadOnly}
                    />
                    <span className="mt-1  h5">-</span>
                    <input
                        type="text"
                        className="border-0 date-month"
                        id="monthValue"
                        ref={monthValue}
                        placeholder={wordSplit("MMM")}
                        value={textMonth}
                        maxLength={3}
                        onChange={handleTextDateChange}
                        onKeyDown={handleMonthKeyEvent}
                        onFocus={(e) => e.target.select()}
                        required
                        autoComplete="off"
                        disabled={props?.isReadOnly}
                    />
                    <span className="mt-1  h5">-</span>
                    <input
                        type="text"
                        className="date-day select-all-on-touch"
                        id="dayValue"
                        ref={dayValue}
                        placeholder={wordSplit("DD")}
                        autoComplete="off"
                        value={textDay !== "" ? `0${textDay}`.slice(-2) : ""}
                        onChange={handleTextDateChange}
                        onKeyDown={handleDayKeyEvent}
                        onFocus={(e) => e.target.select()}
                        required
                        disabled={props?.isReadOnly}
                    />
                </div>
                <div id="basic-addon2" className="dateFieldWrapper">
                    <input
                        type="date"
                        id="actualDate"
                        ref={actualDateRef}
                        className="date-field"
                        min={props?.minMaxDate ? `${minDateForLock}` : ""}
                        max={props?.minMaxDate ? `${presentYear}-12-31` : ""}
                        aria-label="calendar picker"
                        onChange={handleDateChange}
                        onKeyDown={triggerCallendar}
                    />
                    <button
                        ref={datePickerBtnRef}
                        onClick={showCallendar}
                        className="date-btn"
                        aria-label="date Picker"
                        aria-describedby="yearValue monthValue dayValue"
                        disabled={props?.isReadOnly}
                    >
                        <span className="aui-datepicker" />
                    </button>
                </div>
            </div>
            <div
                className="datepicker-error"
                id="date-error"
                aria-live="assertive"
            >
                <span>{dateError}</span>
            </div>
        </DatepickerWrapper>
    );
};
export default Datepicker;
