import CONSTANTS from "common/constants";
import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "styles/popover.scss";
import "styles/browser-customstyle.scss";
import "styles/tooltip.scss";
import { EMSMeasuresWrapper } from "./styled";

interface Props {
    data: any;
    handleEMSMeasureChange?: any;
    submit?: any;
    readOnly?: any;
    isSaveInProgress?: any;
    disabled?: any;
    saveDraft?: any;
    isSubmitted?: boolean;
    isMeasureTableLoading?: boolean;
    submitTooltip?: boolean;
    handleOnchangeCheck?: any;
    checkboxes?: any;
}
const EMSMeasuresTable = (props: Props) => {
    const formRef = useRef<HTMLFormElement>(null);
    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };
    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });

    const submitCreatedMeasure = (event: KeyboardEvent) => {
        if (!props.readOnly && props.submit && event.key === "Enter") {
            event.preventDefault();
            formRef.current?.requestSubmit();
        }
    };
    const saveAsDraft = (event: any) => {
        if (formRef.current?.checkValidity()) {
            props.saveDraft(event);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            submitCreatedMeasure(event);
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [props.submit, !props.readOnly]);

    return (
        <EMSMeasuresWrapper>
            <form ref={formRef} onSubmit={props.submit}>
                <div
                    role="group"
                    aria-labelledby="tables"
                    className="measure-group-table"
                >
                    <label id="tables" className="sr-only">
                        Measures Tables
                    </label>
                    <div className="d-flex flex-row-reverse mb-1">
                        <div className="mt-sm-1">
                            {!props.readOnly && (
                                <span className="font-red mr-4">
                                    * Mandatory
                                </span>
                            )}
                            <span className="sr-only">,</span>N (Numerator), D
                            (Denominator), % (Percentage)
                        </div>
                    </div>
                    <div className="ems-measure-table">
                        {props?.data?.measuresData?.map(
                            (measures: any, index: number) => {
                                return (
                                    <table
                                        className="aui-responsive-status-table"
                                        aria-label={measures[0].groupName}
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Measures ID
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Measures Name
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="aui-th">
                                                        Description
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center"
                                                >
                                                    <div className="aui-th">
                                                        Type
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center"
                                                >
                                                    <div className="aui-th justify-content-lg-center">
                                                        Q1
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center"
                                                >
                                                    <div className="aui-th justify-content-lg-center">
                                                        Q2
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center"
                                                >
                                                    <div className="aui-th justify-content-lg-center">
                                                        Q3
                                                    </div>
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-center"
                                                >
                                                    <div className="aui-th justify-content-lg-center">
                                                        Q4
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="ems-measure-subheading-tr">
                                                <td colSpan={8}>
                                                    <div className="w-100">
                                                        {props?.checkboxes[
                                                            index
                                                        ] &&
                                                            (index === 0 ||
                                                                props.readOnly) && (
                                                                <div className="ems-measure-subheading">
                                                                    <span>
                                                                        <span className="sr-only">
                                                                            Sub
                                                                            heading{" "}
                                                                        </span>
                                                                        {
                                                                            measures[0]
                                                                                .groupName
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        {measures[0]
                                                            .groupName &&
                                                            (measures[0]
                                                                .selectionType ===
                                                                CONSTANTS.ONE ||
                                                                measures[0]
                                                                    .selectionType ===
                                                                    CONSTANTS.ALL) &&
                                                            !props.readOnly && (
                                                                <div className="w-100">
                                                                    <div className="ems-measure-subheading">
                                                                        <span>
                                                                            <span className="sr-only">
                                                                                Sub
                                                                                heading{" "}
                                                                            </span>
                                                                            {
                                                                                measures[0]
                                                                                    .groupName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="ems-measure-subheading-checkbox">
                                                                        <div className="d-flex checkbox-container">
                                                                            <div className="form-group form-check-bordered mb-0">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name={`checkbox-${measures[0].selectionType}-${measures[0].groupId}`}
                                                                                    id={`checkbox-${measures[0].selectionType}-${measures[0].groupId}`}
                                                                                    aria-describedby={`checkbox-label-${measures[0].groupId}`}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        props?.handleOnchangeCheck(
                                                                                            e,
                                                                                            index,
                                                                                            measures[0]
                                                                                                .groupId
                                                                                        )
                                                                                    }
                                                                                    checked={
                                                                                        props
                                                                                            ?.checkboxes[
                                                                                            index
                                                                                        ]
                                                                                    }
                                                                                />
                                                                                <label
                                                                                    htmlFor={`checkbox-${measures[0].selectionType}-${measures[0].groupId}`}
                                                                                >
                                                                                    <span
                                                                                        id={`checkbox-label-${measures[0].groupId}`}
                                                                                        aria-label="Deselect
                                                                                the
                                                                                checkbox
                                                                                to
                                                                                opt
                                                                                out
                                                                                of
                                                                                these
                                                                                measures."
                                                                                    >
                                                                                        Deselect
                                                                                        the
                                                                                        checkbox
                                                                                        to
                                                                                        opt
                                                                                        out
                                                                                        of
                                                                                        these
                                                                                        measures.
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        {measures[0]
                                                                            .selectionType ===
                                                                            CONSTANTS.ONE && (
                                                                            <div className="note-sec">
                                                                                {ReactHtmlParser(
                                                                                    measures[0]
                                                                                        ?.groupDescription
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </td>
                                            </tr>

                                            {props?.checkboxes[index] &&
                                                measures?.map(
                                                    (measure: any) => {
                                                        const firstQuarterIndex =
                                                            measure.values
                                                                ? measure.values.findIndex(
                                                                      (
                                                                          quarterValues: any
                                                                      ) =>
                                                                          quarterValues.quarter ==
                                                                          1
                                                                  )
                                                                : -1;
                                                        const firstQuarterPercentage =
                                                            firstQuarterIndex >=
                                                            0
                                                                ? measure
                                                                      .values[
                                                                      firstQuarterIndex
                                                                  ].numerator !=
                                                                      null &&
                                                                  measure
                                                                      .values[
                                                                      firstQuarterIndex
                                                                  ]
                                                                      .denominator !=
                                                                      null
                                                                    ? (measure
                                                                          .values[
                                                                          firstQuarterIndex
                                                                      ]
                                                                          .numerator /
                                                                          measure
                                                                              .values[
                                                                              firstQuarterIndex
                                                                          ]
                                                                              .denominator) *
                                                                      100
                                                                    : -1
                                                                : -1;
                                                        const secondQuarterIndex =
                                                            measure.values
                                                                ? measure.values.findIndex(
                                                                      (
                                                                          quarterValues: any
                                                                      ) =>
                                                                          quarterValues.quarter ==
                                                                          2
                                                                  )
                                                                : -1;
                                                        const secondQuarterPercentage =
                                                            secondQuarterIndex >=
                                                            0
                                                                ? measure
                                                                      .values[
                                                                      secondQuarterIndex
                                                                  ].numerator !=
                                                                      null &&
                                                                  measure
                                                                      .values[
                                                                      secondQuarterIndex
                                                                  ]
                                                                      .denominator !=
                                                                      null
                                                                    ? (measure
                                                                          .values[
                                                                          secondQuarterIndex
                                                                      ]
                                                                          .numerator /
                                                                          measure
                                                                              .values[
                                                                              secondQuarterIndex
                                                                          ]
                                                                              .denominator) *
                                                                      100
                                                                    : -1
                                                                : -1;
                                                        const thirdQuarterIndex =
                                                            measure.values
                                                                ? measure.values.findIndex(
                                                                      (
                                                                          quarterValues: any
                                                                      ) =>
                                                                          quarterValues.quarter ==
                                                                          3
                                                                  )
                                                                : -1;

                                                        const thirdQuarterPercentage =
                                                            thirdQuarterIndex >=
                                                            0
                                                                ? measure
                                                                      .values[
                                                                      thirdQuarterIndex
                                                                  ].numerator !=
                                                                      null &&
                                                                  measure
                                                                      .values[
                                                                      thirdQuarterIndex
                                                                  ]
                                                                      .denominator !=
                                                                      null
                                                                    ? (measure
                                                                          .values[
                                                                          thirdQuarterIndex
                                                                      ]
                                                                          .numerator /
                                                                          measure
                                                                              .values[
                                                                              thirdQuarterIndex
                                                                          ]
                                                                              .denominator) *
                                                                      100
                                                                    : -1
                                                                : -1;
                                                        const fourthQuarterIndex =
                                                            measure.values
                                                                ? measure.values.findIndex(
                                                                      (
                                                                          quarterValues: any
                                                                      ) =>
                                                                          quarterValues.quarter ==
                                                                          4
                                                                  )
                                                                : -1;
                                                        const fourthQuarterPercentage =
                                                            fourthQuarterIndex >=
                                                            0
                                                                ? measure
                                                                      .values[
                                                                      fourthQuarterIndex
                                                                  ].numerator !=
                                                                      null &&
                                                                  measure
                                                                      .values[
                                                                      fourthQuarterIndex
                                                                  ]
                                                                      .denominator !=
                                                                      null
                                                                    ? (measure
                                                                          .values[
                                                                          fourthQuarterIndex
                                                                      ]
                                                                          .numerator /
                                                                          measure
                                                                              .values[
                                                                              fourthQuarterIndex
                                                                          ]
                                                                              .denominator) *
                                                                      100
                                                                    : -1
                                                                : -1;
                                                        return (
                                                            <tr className="aui-table-status-normal">
                                                                <td data-title="Measures ID">
                                                                    <div className="aui-td align-items-start">
                                                                        <div
                                                                            id={`measure-${measure.propertyId}`}
                                                                        >
                                                                            {ReactHtmlParser(
                                                                                measure.code
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-title="Measures Name">
                                                                    <div className="aui-td align-items-start">
                                                                        {ReactHtmlParser(
                                                                            measure.name
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td data-title="Description">
                                                                    <div className="aui-td align-items-start">
                                                                        {ReactHtmlParser(
                                                                            measure.description
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td data-title="Type">
                                                                    <div className="aui-td align-items-start">
                                                                        <div className="w-100">
                                                                            <div
                                                                                className="ems-measure-label"
                                                                                id={`num-${measure.propertyId}`}
                                                                            >
                                                                                <span>
                                                                                    N
                                                                                </span>
                                                                                {!props.readOnly &&
                                                                                    measure.isMandatory && (
                                                                                        <span className="font-red ems-measure-star">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                {measure?.numeratorHelptext && (
                                                                                    <button
                                                                                        className="aha-icon-help ems-measure-help btn btn-text"
                                                                                        type="button"
                                                                                        data-toggle="popover"
                                                                                        {...(!isSafariBrowser()
                                                                                            ? {
                                                                                                  "data-trigger":
                                                                                                      "focus",
                                                                                              }
                                                                                            : {})}
                                                                                        data-html="true"
                                                                                        data-container={`#num-${measure.propertyId}`}
                                                                                        data-content={
                                                                                            measure.numeratorHelptext
                                                                                        }
                                                                                    >
                                                                                        <div className="sr-only">
                                                                                            {" "}
                                                                                            measure
                                                                                            id
                                                                                            {ReactHtmlParser(
                                                                                                measure.code
                                                                                            )}{" "}
                                                                                            numerator
                                                                                            help
                                                                                            text
                                                                                        </div>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                className="ems-measure-label"
                                                                                id={`den-${measure.propertyId}`}
                                                                            >
                                                                                <span>
                                                                                    D
                                                                                </span>
                                                                                {!props.readOnly &&
                                                                                    measure.isMandatory && (
                                                                                        <span className="font-red ems-measure-star">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                                {measure?.denominatorHelptext && (
                                                                                    <button
                                                                                        className="aha-icon-help ems-measure-help btn btn-text"
                                                                                        type="button"
                                                                                        data-toggle="popover"
                                                                                        {...(!isSafariBrowser()
                                                                                            ? {
                                                                                                  "data-trigger":
                                                                                                      "focus",
                                                                                              }
                                                                                            : {})}
                                                                                        data-html="true"
                                                                                        data-container={`#den-${measure.propertyId}`}
                                                                                        data-content={
                                                                                            measure.denominatorHelptext
                                                                                        }
                                                                                    >
                                                                                        <div className="sr-only">
                                                                                            {" "}
                                                                                            measure
                                                                                            id
                                                                                            {ReactHtmlParser(
                                                                                                measure.code
                                                                                            )}{" "}
                                                                                            denominator
                                                                                            help
                                                                                            text
                                                                                        </div>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                %
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-title="Q1">
                                                                    <div className="aui-td align-items-start p-0">
                                                                        <div className="w-100 ems-measure-block ems-measure-bg">
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="numerator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}numQ1`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            firstQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      firstQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      firstQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          firstQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          firstQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    numerator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    N:{" "}
                                                                                </span>
                                                                                {firstQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    firstQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    firstQuarterIndex
                                                                                ]
                                                                                    .numerator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    firstQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              firstQuarterIndex
                                                                                          ]
                                                                                              .numerator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="denominator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}denQ1`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            firstQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      firstQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      firstQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          firstQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          firstQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    denominator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    D:{" "}
                                                                                </span>
                                                                                {firstQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    firstQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    firstQuarterIndex
                                                                                ]
                                                                                    .denominator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    firstQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              firstQuarterIndex
                                                                                          ]
                                                                                              .denominator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            <div
                                                                                className="ems-measure-per"
                                                                                {...(!props.readOnly
                                                                                    ? {
                                                                                          role: "alert",
                                                                                      }
                                                                                    : {})}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    percentage
                                                                                    calculated
                                                                                </span>
                                                                                {props.readOnly ? (
                                                                                    <span>
                                                                                        {isNaN(
                                                                                            firstQuarterPercentage
                                                                                        ) ||
                                                                                        firstQuarterPercentage >
                                                                                            100 ||
                                                                                        firstQuarterPercentage <
                                                                                            0
                                                                                            ? ""
                                                                                            : `${firstQuarterPercentage.toFixed(
                                                                                                  2
                                                                                              )}%`}
                                                                                    </span>
                                                                                ) : (
                                                                                    <>
                                                                                        {firstQuarterIndex >=
                                                                                            0 &&
                                                                                            measure
                                                                                                .values[
                                                                                                firstQuarterIndex
                                                                                            ]
                                                                                                .numerator !==
                                                                                                null &&
                                                                                            measure
                                                                                                .values[
                                                                                                firstQuarterIndex
                                                                                            ]
                                                                                                .denominator !==
                                                                                                null && (
                                                                                                <>
                                                                                                    {isNaN(
                                                                                                        firstQuarterPercentage
                                                                                                    ) ||
                                                                                                    firstQuarterPercentage >
                                                                                                        100 ||
                                                                                                    firstQuarterPercentage <
                                                                                                        0
                                                                                                        ? ""
                                                                                                        : `${firstQuarterPercentage.toFixed(
                                                                                                              2
                                                                                                          )}%`}
                                                                                                </>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-title="Q2">
                                                                    <div className="aui-td align-items-start p-0">
                                                                        <div className="w-100 ems-measure-block">
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="numerator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}numQ2`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            secondQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      secondQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      secondQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          secondQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          secondQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    numerator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    N:{" "}
                                                                                </span>
                                                                                {secondQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    secondQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    secondQuarterIndex
                                                                                ]
                                                                                    .numerator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    secondQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              secondQuarterIndex
                                                                                          ]
                                                                                              .numerator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="denominator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}denQ2`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            secondQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      secondQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      secondQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          secondQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          secondQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    denominator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    D:{" "}
                                                                                </span>
                                                                                {secondQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    secondQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    secondQuarterIndex
                                                                                ]
                                                                                    .denominator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    secondQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              secondQuarterIndex
                                                                                          ]
                                                                                              .denominator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            <div
                                                                                className="ems-measure-per"
                                                                                {...(!props.readOnly
                                                                                    ? {
                                                                                          role: "alert",
                                                                                      }
                                                                                    : {})}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    percentage
                                                                                    calculated
                                                                                </span>
                                                                                {props.readOnly ? (
                                                                                    <span>
                                                                                        {isNaN(
                                                                                            secondQuarterPercentage
                                                                                        ) ||
                                                                                        secondQuarterPercentage >
                                                                                            100 ||
                                                                                        secondQuarterPercentage <
                                                                                            0
                                                                                            ? ""
                                                                                            : `${secondQuarterPercentage.toFixed(
                                                                                                  2
                                                                                              )}%`}
                                                                                    </span>
                                                                                ) : (
                                                                                    <>
                                                                                        {secondQuarterIndex >=
                                                                                            0 &&
                                                                                            measure
                                                                                                .values[
                                                                                                secondQuarterIndex
                                                                                            ]
                                                                                                .numerator !==
                                                                                                null &&
                                                                                            measure
                                                                                                .values[
                                                                                                secondQuarterIndex
                                                                                            ]
                                                                                                .denominator !==
                                                                                                null && (
                                                                                                <>
                                                                                                    {isNaN(
                                                                                                        secondQuarterPercentage
                                                                                                    ) ||
                                                                                                    secondQuarterPercentage >
                                                                                                        100 ||
                                                                                                    secondQuarterPercentage <
                                                                                                        0
                                                                                                        ? ""
                                                                                                        : `${secondQuarterPercentage.toFixed(
                                                                                                              2
                                                                                                          )}%`}
                                                                                                </>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-title="Q3">
                                                                    <div className="aui-td align-items-start p-0">
                                                                        <div className="w-100 ems-measure-block ems-measure-bg">
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="numerator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}numQ3`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            thirdQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      thirdQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      thirdQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          thirdQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          thirdQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    numerator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    N:{" "}
                                                                                </span>
                                                                                {thirdQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    thirdQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    thirdQuarterIndex
                                                                                ]
                                                                                    .numerator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    thirdQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              thirdQuarterIndex
                                                                                          ]
                                                                                              .numerator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="denominator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}denQ3`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            thirdQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      thirdQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      thirdQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          thirdQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          thirdQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    denominator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    D:{" "}
                                                                                </span>
                                                                                {thirdQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    thirdQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    thirdQuarterIndex
                                                                                ]
                                                                                    .denominator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    thirdQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              thirdQuarterIndex
                                                                                          ]
                                                                                              .denominator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            <div
                                                                                className="ems-measure-per"
                                                                                {...(!props.readOnly
                                                                                    ? {
                                                                                          role: "alert",
                                                                                      }
                                                                                    : {})}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    percentage
                                                                                    calculated
                                                                                </span>
                                                                                {props.readOnly ? (
                                                                                    <span>
                                                                                        {isNaN(
                                                                                            thirdQuarterPercentage
                                                                                        ) ||
                                                                                        thirdQuarterPercentage >
                                                                                            100 ||
                                                                                        thirdQuarterPercentage <
                                                                                            0
                                                                                            ? ""
                                                                                            : `${thirdQuarterPercentage.toFixed(
                                                                                                  2
                                                                                              )}%`}
                                                                                    </span>
                                                                                ) : (
                                                                                    <>
                                                                                        {thirdQuarterIndex >=
                                                                                            0 &&
                                                                                            measure
                                                                                                .values[
                                                                                                thirdQuarterIndex
                                                                                            ]
                                                                                                .numerator !==
                                                                                                null &&
                                                                                            measure
                                                                                                .values[
                                                                                                thirdQuarterIndex
                                                                                            ]
                                                                                                .denominator !==
                                                                                                null && (
                                                                                                <>
                                                                                                    {isNaN(
                                                                                                        thirdQuarterPercentage
                                                                                                    ) ||
                                                                                                    thirdQuarterPercentage >
                                                                                                        100 ||
                                                                                                    thirdQuarterPercentage <
                                                                                                        0
                                                                                                        ? ""
                                                                                                        : `${thirdQuarterPercentage.toFixed(
                                                                                                              2
                                                                                                          )}%`}
                                                                                                </>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td data-title="Q4">
                                                                    <div className="aui-td align-items-start p-0">
                                                                        <div className="w-100 ems-measure-block">
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="numerator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}numQ4`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            fourthQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      fourthQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      fourthQuarterIndex
                                                                                                  ]
                                                                                                      .numerator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          fourthQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          fourthQuarterIndex
                                                                                                      ]
                                                                                                          .numerator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    numerator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    N:{" "}
                                                                                </span>
                                                                                {fourthQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    fourthQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    fourthQuarterIndex
                                                                                ]
                                                                                    .numerator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    fourthQuarterIndex
                                                                                ]
                                                                                    .numerator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              fourthQuarterIndex
                                                                                          ]
                                                                                              .numerator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            {!props.readOnly && (
                                                                                <>
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control ems-measure-field"
                                                                                        aria-label="denominator"
                                                                                        aria-describedby={`measure-${measure.propertyId}`}
                                                                                        name={`${measure.code}denQ4`}
                                                                                        min="0"
                                                                                        max="999999"
                                                                                        onChange={
                                                                                            props.handleEMSMeasureChange
                                                                                        }
                                                                                        defaultValue={
                                                                                            fourthQuarterIndex >=
                                                                                            0
                                                                                                ? measure
                                                                                                      .values[
                                                                                                      fourthQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      null ||
                                                                                                  measure
                                                                                                      .values[
                                                                                                      fourthQuarterIndex
                                                                                                  ]
                                                                                                      .denominator ===
                                                                                                      "" ||
                                                                                                  isNaN(
                                                                                                      measure
                                                                                                          .values[
                                                                                                          fourthQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                  )
                                                                                                    ? ""
                                                                                                    : measure
                                                                                                          .values[
                                                                                                          fourthQuarterIndex
                                                                                                      ]
                                                                                                          .denominator
                                                                                                : ""
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )}
                                                                            {/* In non edit mode and remove d-none */}
                                                                            <div
                                                                                className={`ems-measure-value ${
                                                                                    props.readOnly
                                                                                        ? ""
                                                                                        : "d-none"
                                                                                }`}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    denominator:
                                                                                </span>
                                                                                <span
                                                                                    className="d-inline d-lg-none"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    D:{" "}
                                                                                </span>
                                                                                {fourthQuarterIndex >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    fourthQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    null &&
                                                                                measure
                                                                                    .values[
                                                                                    fourthQuarterIndex
                                                                                ]
                                                                                    .denominator >=
                                                                                    0 &&
                                                                                measure
                                                                                    .values[
                                                                                    fourthQuarterIndex
                                                                                ]
                                                                                    .denominator !==
                                                                                    ""
                                                                                    ? parseInt(
                                                                                          measure
                                                                                              .values[
                                                                                              fourthQuarterIndex
                                                                                          ]
                                                                                              .denominator
                                                                                      )
                                                                                    : ""}
                                                                            </div>
                                                                            <div
                                                                                className="ems-measure-per"
                                                                                {...(!props.readOnly
                                                                                    ? {
                                                                                          role: "alert",
                                                                                      }
                                                                                    : {})}
                                                                            >
                                                                                <span className="sr-only">
                                                                                    percentage
                                                                                    calculated
                                                                                </span>
                                                                                {props.readOnly ? (
                                                                                    <span>
                                                                                        {isNaN(
                                                                                            fourthQuarterPercentage
                                                                                        ) ||
                                                                                        fourthQuarterPercentage >
                                                                                            100 ||
                                                                                        fourthQuarterPercentage <
                                                                                            0
                                                                                            ? ""
                                                                                            : `${fourthQuarterPercentage.toFixed(
                                                                                                  2
                                                                                              )}%`}
                                                                                    </span>
                                                                                ) : (
                                                                                    <>
                                                                                        {fourthQuarterIndex >=
                                                                                            0 &&
                                                                                            measure
                                                                                                .values[
                                                                                                fourthQuarterIndex
                                                                                            ]
                                                                                                .numerator !==
                                                                                                null &&
                                                                                            measure
                                                                                                .values[
                                                                                                fourthQuarterIndex
                                                                                            ]
                                                                                                .denominator !==
                                                                                                null && (
                                                                                                <>
                                                                                                    {isNaN(
                                                                                                        fourthQuarterPercentage
                                                                                                    ) ||
                                                                                                    fourthQuarterPercentage >
                                                                                                        100 ||
                                                                                                    fourthQuarterPercentage <
                                                                                                        0
                                                                                                        ? ""
                                                                                                        : `${fourthQuarterPercentage.toFixed(
                                                                                                              2
                                                                                                          )}%`}
                                                                                                </>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                        </tbody>
                                    </table>
                                );
                            }
                        )}
                    </div>
                    <div className="d-sm-flex justify-content-end pb-4 mb-1 ems-measure-table-btn">
                        {!props.readOnly && !props.isSubmitted && (
                            <button
                                disabled={
                                    props.disabled || props.isSaveInProgress
                                }
                                className={`${
                                    !props.readOnly &&
                                    !props.isSubmitted &&
                                    "ems-measure-btn"
                                } btn btn-round btn-primary mr-4`}
                                onClick={saveAsDraft}
                            >
                                Save as Draft
                            </button>
                        )}
                        {props.submitTooltip &&
                            !props.readOnly &&
                            !props.isSubmitted && (
                                <div
                                    id="submit-measures-btn"
                                    className="submit-measures-btn cst-tooltip"
                                >
                                    <button
                                        className="btn btn-primary btn-round ems-measure-sub mt-4 mt-sm-0 tooltip-item"
                                        aria-describedby="emsMeasuresBtn"
                                    >
                                        Submit
                                    </button>
                                    <div
                                        className="tooltip tooltip-bottom ems-measure-tooltip"
                                        role="tooltip"
                                        id="emsMeasuresBtn"
                                    >
                                        <div className="arrow" />
                                        <div className="tooltip-inner">
                                            Please enter all the mandatory
                                            measures to submit and if a measure
                                            has no value, kindly enter
                                            &apos;0&apos;. Alternatively,
                                            &apos;Save as Draft&apos; if you
                                            would like to submit it later.
                                        </div>
                                    </div>
                                </div>
                            )}
                        {!props.submitTooltip &&
                            (props.isSubmitted || props.submit) &&
                            !props.readOnly && (
                                <button
                                    disabled={
                                        props.disabled || props.isSaveInProgress
                                    }
                                    type="submit"
                                    aria-label="Submit Measure data"
                                    className="ems-measure-btn btn btn-round btn-primary"
                                >
                                    Submit
                                </button>
                            )}
                    </div>
                </div>
            </form>
        </EMSMeasuresWrapper>
    );
};
export default EMSMeasuresTable;
